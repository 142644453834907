import axios from "axios";
import config from "./config";
import { authHeaders } from "./_services/utils.service";

const fileApi = {
    async getFiles(token) {
        return axios.get(`${config.apiUrl}/api/v1/file/`, authHeaders(token));
    },
    createFile() {
        // Return as api str due to dropzone library handles multipart post
        return config.apiUrl+"/api/v1/file/";
    },
    async deleteFile(token, fileId) {
        return axios.delete(`${config.apiUrl}/api/v1/file/${fileId}`, authHeaders(token));
    },
    async getFileDetails(token, fileId){
        return axios.get(`${config.apiUrl}/api/v1/file/get_file_details/${fileId}`, authHeaders(token));
    },
    getFile(fileId) {
        // Return as api str due to papa parse library handles get
        return config.apiUrl + "/api/v1/file/get_file/" + fileId;
    }

}

const roleApi = {
    async createRole(token, data) {
        return axios.post(`${config.apiUrl}/api/v1/roles/`, data, authHeaders(token));
    },

    async getRoles(token, skip, limit) {
        return axios.get(`${config.apiUrl}/api/v1/roles/?skip=${skip}&limit=${limit}`, authHeaders(token));
    },

    async updateRole(token, id, data) {
        return axios.put(`${config.apiUrl}/api/v1/roles/${id}`, data, authHeaders(token));
    },

    async removeRole(token, data) {
        return axios.post(`${config.apiUrl}/api/v1/roles/remove`, data, authHeaders(token));
    },
};

const taskApi = {
    async createTask(token, data) {
        return axios.post(`${config.apiUrl}/api/v1/tasks/`, data, authHeaders(token));
    },
    async getTasks(token) {
        return axios.get(`${config.apiUrl}/api/v1/tasks/get_active_tasks`, authHeaders(token));
    },
    async completeTask(token, id) {
        return axios.put(`${config.apiUrl}/api/v1/tasks/complete/${id}`, {}, authHeaders(token));
    },
    async repeatTask(token, id) {
        return axios.put(`${config.apiUrl}/api/v1/tasks/repeat/${id}`, {}, authHeaders(token));
    },
    async deleteTask(token, id) {
        return axios.put(`${config.apiUrl}/api/v1/tasks/delete/${id}`, {}, authHeaders(token));
    },
};

const sessionApi = {
    async getSessions(token, skip, limit) {
        return axios.get(`${config.apiUrl}/api/v1/session/sessions_dashboard/?skip=${skip}&limit=${limit}`, authHeaders(token));
    }
}

const userApi = {
    async logInGetToken(username, password) {
        const params = new URLSearchParams();
        params.append("username", username);
        params.append("password", password);

        return axios.post(`${config.apiUrl}/api/v1/login/access-token`, params);
    },
    async getMe(token) {
        return axios.get(`${config.apiUrl}/api/v1/users/me`, authHeaders(token));
    },
    async updateMe(token, data) {
        return axios.put(
            `${config.apiUrl}/api/v1/users/me`,
            data,
            authHeaders(token),
        );
    },
    async getUsers(token, skip, limit) {
        return axios.get(`${config.apiUrl}/api/v1/users/?skip=${skip}&limit=${limit}`, authHeaders(token));
    },
    async updateUser(token, userId, data) {
        return axios.put(`${config.apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
    },
    async updateUserPreferences(token, userId, data) {
        return axios.put(`${config.apiUrl}/api/v1/users/preferences/${userId}`, data, authHeaders(token));
    },
    async createUser(token, data) {
        return axios.post(`${config.apiUrl}/api/v1/users/`, data, authHeaders(token));
    },
    async createUserOpen(data) {
        return axios.post(`${config.apiUrl}/api/v1/users/open`, data);
    },
    async passwordRecovery(email) {
        return axios.post(`${config.apiUrl}/api/v1/password-recovery/${email}`);
    },
    async resetPassword(password, token) {
        return axios.post(`${config.apiUrl}/api/v1/reset-password/`, {
            new_password: password,
            token,
        });
    },
    async removeUser(token, data) {
        return axios.post(`${config.apiUrl}/api/v1/users/remove`, data, authHeaders(token));
    },
    async getExperimentsForVisualizer(token) {
        const params = new URLSearchParams();
        params.append("skip", 0);
        params.append("limit", 100);
        return axios.get(`${config.apiUrl}/api/v1/experiments/visualizer`, authHeaders(token), params);
    },
    async getExperimentsForWrangler(token) {
        const params = new URLSearchParams();
        params.append("skip", 0);
        params.append("limit", 100);
        return axios.get(`${config.apiUrl}/api/v1/experiments/wrangler`, authHeaders(token), params);
    },
    async getSummaryStatistics(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/summarystatistics`, authHeaders(token));
    },
    async getExperimentViewer(exp_id, token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/experiment_viewer/${exp_id}`, authHeaders(token));
    },
    async getExperimentList(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/experiment_list`, authHeaders(token));
    },
    async getExperimentCount(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/count`, authHeaders(token));
    },
    async getAllExpriments(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/`, authHeaders(token));
    },
    async getAllExpForSteel(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/steelrefine`, authHeaders(token));
    },
    async getAllExpForAl(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/aluminumrefine`, authHeaders(token));
    },
    async getAllProcessCount(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/processcount`, authHeaders(token));
    },
    async getAllUniExperiments(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/uniqueExpriments`, authHeaders(token));
    },
    getExperimentViewerN(exp_num, token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/experiment_viewer_n/${exp_num}`, authHeaders(token));
    },
    getImage(image_link, token) {
        image_link = "I267_steel-(high-hard)_abrasive-blasted_immersion-zinc-phosphate-wo-chrome-seal_MIL-DTL-53022_MIL-DTL-53039.jpg"
        return axios.get(`${config.apiUrl}/api/v1/images/${image_link}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'arraybuffer'
        });
    },
    async getExperimentCol(token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/experiment_cols`, authHeaders(token));
    },
    async simpleSearch(query, page, limit, token) {
        return axios.get(`${config.apiUrl}/api/v1/experiments/search/${query}`,  {
            params: {
                page: page,
                limit: limit
            },
            headers: {
                Authorization: `Bearer ${token}`,
            }
        });
    },
    async advancedSearch(query, token) {
        return axios.put(`${config.apiUrl}/api/v1/experiments/advanced_search`, query, authHeaders(token));
    },
    async getDpResultFromServerImage(data, token) {
        return axios.post(`${config.apiUrl}/api/v1/deeplearning/getDpResult`, data, authHeaders(token));
    },
    async updateExperimentFrame(frame_id, data, token) {
        return axios.put(`${config.apiUrl}/api/v1/experimentframes/update_experiment_frame/${frame_id}`, data, authHeaders(token));
    },
    async getExperimentFrame(exp_id, token) {
        return axios.get(`${config.apiUrl}/api/v1/experimentframes/${exp_id}`, authHeaders(token));
    },
    async createExperiment(expIn, token) {
        console.log('expIn: '+JSON.stringify(expIn));
        return axios.post(`${config.apiUrl}/api/v1/experiments/`, expIn, authHeaders(token));
    },
    async createExperimentFrame(expFrameIn, token) {
        return axios.post(`${config.apiUrl}/api/v1/experimentframes/createWithoutImage`, expFrameIn, authHeaders(token));
    },
    // async createExperimentFrame(expFrameIn, token) {
    //     return axios.post(`${config.apiUrl}/api/v1/experimentframes/`);
    // },
};

const datawranglerApi = {
    async summary(token, fileId) {
        return axios.get(`${config.apiUrl}/api/v1/data_wrangler/summary/${fileId}`, authHeaders(token));
    }
}


export {userApi, datawranglerApi, fileApi, roleApi, taskApi, sessionApi}
