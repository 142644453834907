import React, {useEffect, useState} from 'react';
import Aux from "components/utils/_Aux";
import navigation from 'menu-items';

function Breadcrumb(props) {

    const [state, setState] = useState({});

    const getCollapse = (item) => {
        if (item.children) {
            (item.children).filter( collapse => {
                if (collapse.type && collapse.type === 'collapse') {
                    getCollapse(collapse,);
                } else if (collapse.type && collapse.type === 'item') {
                    if (document.location.pathname === collapse.url) {
                        setState({item: collapse, main: item});
                    }
                }
                return false;
            });
        }
    };

    useEffect(() => {
        (navigation.items).map((item, index) => {
            if (item.type && item.type === 'group') {
                getCollapse(item, index);
            }
            return false;
        });
    }, [navigation.items, props]);

    return  (
        <Aux>
            <div className="page-header">
                <div className="page-block">
                    <div className="row align-items-center">
                        <div className="col-md-12">
                            <div className="page-header-title">
                                <h5 className="m-b-10">{state.item?state.item.title:''}</h5>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </Aux>
    );
}

export default Breadcrumb;
