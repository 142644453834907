import React from 'react';
import {Link, NavLink} from 'react-router-dom';
import {connect} from 'react-redux';
import { withRouter } from "react-router-dom";
import {userActions} from '_actions';
import Aux from "components/utils/_Aux";
import {ProjectDescription} from "components/ProjectDescription";

class RegisterPage extends React.Component {
    constructor(props) {
        super(props);

        this.formElem = React.createRef();
        this.passwordElem = React.createRef();
        this.confirmPasswordElem =  React.createRef();

        this.state = {
            user: {
                full_name: '',
                // lastName: '',
                email: '',
                password: '',
                confirmPassword:'',
            },
            errors: {},
            submitted: false
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
    }


    validatePassword(){
        const {user} = this.state;
        if(user.password !== user.confirmPassword) {
            this.confirmPasswordElem.current.setCustomValidity("Passwords Don't Match");
        } else {
            this.confirmPasswordElem.current.setCustomValidity("");
        }
    }

    handleChange(event) {
        const {name, value} = event.target;
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleSubmit(event) {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }


        const {user} = this.state;

        if (user.full_name && user.email && (user.password===user.confirmPassword)) {
            this.props.register(user);
            this.setState({submitted: true});
        }
        event.preventDefault();
        event.stopPropagation();
    }

    render() {
        // const {registering} = this.props;
        const {user, submitted, errors} = this.state;
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12">
                        <Aux>
                            <div className="row">
                                <div className="col">
                                    <ProjectDescription/>

                                </div>
                                <div className="col">
                                    <div className="auth-wrapper">
                                        <div className="auth-content">
                                            <div className="auth-bg">
                                                <span className="tR"/>
                                                <span className="bL"/>
                                            </div>
                                            <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-4">
                                                        <i className="feather icon-user-plus auth-icon"/>
                                                    </div>
                                                    <h3 className="mb-4">Sign up</h3>
                                                    <form ref={this.formElem} onSubmit={this.handleSubmit}>
                                                        <div className="input-group mb-3">
                                                            <input type="text" className="form-control"
                                                                   placeholder="Name" name="full_name"
                                                                   value={user.full_name} onChange={this.handleChange} required/>
                                                        </div>
                                                        {/*<div className="input-group mb-3">*/}
                                                        {/*    <input type="text" className="form-control"*/}
                                                        {/*           placeholder="Last Name" name="lastName"*/}
                                                        {/*           value={user.lastName} onChange={this.handleChange} required/>*/}
                                                        {/*</div>*/}
                                                        <div className="input-group mb-3">
                                                            <input type="email" className="form-control"
                                                                   placeholder="Email"
                                                                   name="email"
                                                                   value={user.email} onChange={this.handleChange} required/>
                                                        </div>
                                                        <div className="input-group mb-4">
                                                            <input ref={this.passwordElem} type="password" className="form-control"
                                                                   id="password"
                                                                   placeholder="Password"
                                                                   name="password" value={user.password}
                                                                   onChange={this.handleChange} required/>
                                                            <div className="text-danger">{errors.password}</div>
                                                        </div>
                                                        <div className="input-group mb-4">
                                                            <input ref={this.confirmPasswordElem} type="password" className="form-control"
                                                                   id="confirm_password"
                                                                   placeholder="Confirm Password"
                                                                   name="confirmPassword" value={user.confirmPassword}
                                                                   onKeyUp={this.validatePassword}
                                                                   onChange={this.handleChange}/>
                                                        </div>
                                                        <button className="btn btn-primary shadow-2 mb-4"
                                                                type={"submit"}>Sign Up
                                                        </button>
                                                        <p className="mb-0 text-muted">Already have an account? <NavLink
                                                            to="/login">Login</NavLink></p>
                                                    </form>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </Aux>
                    </div>
                </div>
            </div>
        );
    }
}

function mapState(state) {
    const {registering} = state.registration;
    return {registering};
}

const actionCreators = {
    register: userActions.register
}

const connectedRegisterPage = connect(mapState, actionCreators)(withRouter(RegisterPage));
export {connectedRegisterPage as RegisterPage};