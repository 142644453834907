export const COLLAPSE_MENU = 'COLLAPSE_MENU';
export const COLLAPSE_TOGGLE = 'COLLAPSE_TOGGLE';
export const FULL_SCREEN = 'FULL_SCREEN';
export const FULL_SCREEN_EXIT = 'FULL_SCREEN_EXIT';
export const CHANGE_LAYOUT = 'CHANGE_LAYOUT';
export const NAV_CONTENT_LEAVE = 'NAV_CONTENT_LEAVE';
export const NAV_COLLAPSE_LEAVE = 'NAV_COLLAPSE_LEAVE';
export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';
export const ENABLE_DARK_MODE = 'ENABLE_DARK_MODE';
export const DISABLE_DARK_MODE = 'DISABLE_DARK_MODE';
export const NEED_HELP = 'NEED_HELP';
export const ENABLE_FULL_SCREEN_NAVIGATION_REDUCER = 'ENABLE_FULL_SCREEN_NAVIGATION_REDUCER';
export const DISABLE_FULL_SCREEN_NAVIGATION_REDUCER = 'DISABLE_FULL_SCREEN_NAVIGATION_REDUCER';

