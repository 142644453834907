import React, { useState, useEffect } from 'react';
import { Link, useLocation, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '_actions';
import Aux from "components/utils/_Aux";
import "components/LoginPage/LoginPage.css"
import { ProjectDescription } from "components/ProjectDescription";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import config from "config";


function LoginPage() {
    const [inputs, setInputs] = useState({
        email: '',
        password: ''
    });
    const [submitted, setSubmitted] = useState(false);
    const { email, password } = inputs;
    const loggingIn = useSelector(state => state.authentication.loggingIn);
    const dispatch = useDispatch();
    const location = useLocation();

    // reset login status
    useEffect(() => {
        dispatch(userActions.logout());
    }, [dispatch]);

    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (email && password) {
            // get return url from location state or default to home page
            const { from } = location.state || { from: { pathname: "/dashboard" } };
            dispatch(userActions.login(email, password, from));
        }
    }

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-12">
                    {/* <div> */}
                    <Aux>
                        <div className="row">
                            <div className="col">
                                <ProjectDescription />
                            </div>
                            <div className="col">
                                <div className="auth-wrapper">
                                    <div className="row" style={{margin: 10, alignItems: 'center', justifyContent: 'center'}}>
                                        <img src={config.logoImgPath} width="60%" height="30%" style={{margin: 30, alignItems: 'center', justifyContent: 'center'}}/>
                                        <div className="auth-content">
                                            <div className="auth-bg">
                                                <span className={"common-tR " + config.project + "-tR"} />
                                                <span className={"common-bL " + config.project + "-bL"} />
                                                {/*<span className="r"/>*/}
                                                {/*<span className="r s"/>*/}
                                                {/*<span className="r s"/>*/}
                                                {/*<span className="r"/>*/}
                                            </div>
                                            {/* <div className="card">
                                                <div className="card-body text-center">
                                                    <div className="mb-4">
                                                        <i className="feather icon-unlock auth-icon" />
                                                    </div>
                                                    <h3 className="mb-4">Login</h3>
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="input-group mb-3">
                                                            <input type="email" className="form-control" placeholder="Email"
                                                                name="email"
                                                                value={email} onChange={handleChange} />
                                                        </div>
                                                        <div className="input-group mb-4">
                                                            <input type="password" className="form-control"
                                                                placeholder="password"
                                                                name="password" value={password}
                                                                onChange={handleChange} />
                                                        </div>
                                                        <div className="form-group text-left">
                                                            <div className="checkbox checkbox-fill d-inline">
                                                                <input type="checkbox" name="checkbox-fill-1"
                                                                    id="checkbox-fill-a1" />
                                                                <label htmlFor="checkbox-fill-a1" className="cr"> Save
                                                                    credentials</label>
                                                            </div>
                                                        </div>
                                                        <button className="btn btn-primary shadow-2 mb-4"
                                                            type={"submit"}>Login
                                                        </button>
                                                    </form>
                                                    <p className="mb-2 text-muted">Forgot password? <NavLink
                                                        to="/auth/reset-password-1">Reset</NavLink></p>
                                                    <p className="mb-0 text-muted">Don’t have an account? <NavLink
                                                        to="/register">Signup</NavLink></p>
                                                </div>
                                            </div> */}
                                            <Form onSubmit={handleSubmit} className="loginBlock">
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Label>Email</Form.Label>
                                                    <Form.Control type="email" placeholder="Enter email" name="email" value={email} onChange={handleChange} />
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Label>Password</Form.Label>
                                                    <Form.Control type="password" placeholder="Password" name="password" value={password} onChange={handleChange} />
                                                </Form.Group>
                                                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                                    <Form.Check type="checkbox" label="Save credentials" />
                                                </Form.Group>
                                                <Button variant="primary" type="submit" >
                                                    Login
                                                </Button>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Aux>
                </div>
            </div>
        </div>
    );
}

export { LoginPage };