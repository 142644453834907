import React from "react";
import { Button } from 'react-bootstrap';

function ReadOnlyRow({user, handleEditClick, handleDeleteClick}) {
    return (
  	    <tr>
  	        <td><b>{user.id}</b></td>
  	        <td><b>{user.email}</b></td>
  	        <td><b>{user.full_name}</b></td>
  	        <td><b>{user.role_name}</b></td>
  	        <td><b>{user.is_superuser ? "True" : "False"}</b></td>
  	        <td><b>{user.is_active ? "True": "False"}</b></td>
            <td>
                <Button
                    type="button"
                    onClick={(event)=>handleEditClick(event, user)}>
                        Edit
                </Button>
                <Button
                    type="button"
                    onClick={()=>handleDeleteClick(user.id)}>
                        Delete 
                </Button>
            </td>
  	    </tr>
    );
}

export default ReadOnlyRow;
