import React from 'react';
import { Row, Col, Card, Button} from 'react-bootstrap';
import Aux from "components/utils/_Aux";
import * as Icons from "react-icons/fi";
import { history } from "../../_helpers/history";

export default function FileDetails(props) {
    const {fileName, fileType, fileSize, uploadedDate} = props;
    return (
        <Aux>
            <Row>
                <Col>
                    <Card>
                        <Card.Header>
                            <h5>
                                <div className="fileDetails">
                                    <span><Icons.FiFile />{fileName}</span>
                                    <span><Icons.FiType />{fileType}</span>
                                    <span><Icons.FiServer />{fileSize}</span>
                                    <span><Icons.FiCalendar />{uploadedDate}</span>
                                    <span><Button Button variant="info" onClick={() => history.push("/dashboard/filemanager")}>Browse Files</Button></span>
                                </div>
                            </h5>
                        </Card.Header>
                    </Card>
                </Col>
            </Row>
        </Aux>
    );
}
