
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Row, Col, } from 'react-bootstrap';
import * as actionTypes from "_actions";
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Aux from './_Aux';
import * as Icon from 'react-icons/fi';

export default function FullScreenPDFViewer(props) {

    const show = useSelector(state => state.navigation.pdfConfig.fullScreenPDFViewerShow)
    const url = useSelector(state => state.navigation.pdfConfig.pdfUrl)
    const outline = useSelector(state => state.navigation.pdfConfig.outline)
    const scrollable = outline?false:true //useSelector(state => state.navigation.pdfConfig.scrollable)
    const name = useSelector(state => state.navigation.pdfConfig.name)

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch({ type: actionTypes.DISABLE_FULL_SCREEN_NAVIGATION_REDUCER });
    };

    // React PDF Properties
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [pdf, setPdf] = useState(null);


    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages)
        setPdf(pdf)
    }

    const changePage = (offset) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset)
    }

    const previousPage = () => {
        changePage(-1)
    }

    const nextPage = () => {
        changePage(1)
    }

    const onItemClick = ({
        pageNumber: itemPageNumber
    }) => {
        setPageNumber(itemPageNumber)
    }

    return (
        <Aux>
            <Modal show={show} onHide={handleClose}
                size={outline?"xl":"lg"}
                aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>{name} &nbsp; <a className="clickable-link" href={url} download><Icon.FiDownload title={"Download PDF"} /></a></Modal.Title>
                </Modal.Header>
                <Modal.Body style={{height:840}}>
                    <div style={{maxHeight:820, overflow:"scroll"}}>
                        <Document
                            file={url}
                            onLoadSuccess={onDocumentLoadSuccess}>
                            <Row>
                            {outline ? <Col style={{ overflowY: "", height: "750px" }}><Outline onItemClick={onItemClick} /></Col>:""}
                            {scrollable ?<Col>{Array.from(
                                new Array(numPages),
                                (el, index) => (
                                    <Page
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                    />
                                ),
                            )}</Col>:
                            <Col style={{marginTop: "-0px" }}>
                                        <Page pageNumber={pageNumber} style={{ marginTop: "200px"}}
                                            height={770} scale={1.0}/>
                                        <div style={{ marginTop: 0}}>
                                    Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'} &nbsp;&nbsp;
                                    <button
                                        type="button"
                                        disabled={pageNumber <= 1}
                                        onClick={previousPage}>
                                        Previous
                                    </button>
                                    <button
                                        type="button"
                                        disabled={pageNumber >= numPages}
                                        onClick={nextPage}>
                                        Next
                                    </button>
                                </div></Col>
                                }
                                </Row>
                        </Document>
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                </Modal.Footer> */}
            </Modal>
        </Aux>
    );
}
