import {
    fileConstants
} from '_constants';


export const fileActions = {
    updateDataWranglerFile,
    updateVisualizerFile,
    updateDataWranglerDataTableConfig,
    updateDataWranglerSummaryConfig,
    updateDataVisualizerConfig,
    updateDataVisualizerMockConfig,
    updateDataVisualizerPlotlyConfig
};



function updateDataWranglerFile(file_id, file_name) {
    return dispatch => {
        dispatch({
            type: fileConstants.DATAWRANGLER_FILE,
            id: file_id,
            name: file_name
        });
    }
}



function updateVisualizerFile(file_id, file_name) {
    return dispatch => {
        dispatch({
            type: fileConstants.VISUALIZER_FILE,
            id: file_id,
            name: file_name
        });
    }
}

function updateDataVisualizerConfig(response) {
    return dispatch => {
        dispatch({
            type: fileConstants.UPDATE_DATA_VISUALIZER_CONFIG,
            value: response
        });
    }
}

function updateDataVisualizerMockConfig(response) {
    return dispatch => {
        dispatch({
            type: fileConstants.UPDATE_DATA_VISUALIZER_MOCK_CONFIG,
            value: response
        });
    }
}

function updateDataVisualizerPlotlyConfig(response) {
    return dispatch => {
        dispatch({
            type: fileConstants.UPDATE_DATA_VISUALIZER_PLOTLY_CONFIG,
            value: response
        });
    }
}


function updateDataWranglerDataTableConfig(fileId, data, columns, summary, fileDetails) {
    return dispatch => {
        dispatch({
            type: fileConstants.UPDATE_DATA_WRANGLER_DATA_TABLE_CONFIG,
            fileId: fileId,
            data: data,
            columns: columns,
            summary:summary,
            fileDetails:fileDetails
        });
    }
}


function updateDataWranglerSummaryConfig(fileId, summary) {
    return dispatch => {
        dispatch({
            type: fileConstants.UPDATE_DATA_WRANGLER_SUMMARY_CONFIG,
            fileId: fileId,
            summary: summary
        });
    }
}
