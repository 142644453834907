import React from 'react';

const sidebarBadge = (props) => {
    let sidebarBadges = false;
    if (props.items.badge) {
        const badgeClass = ['label', 'pcoded-badge', props.items.badge.type];

        sidebarBadges = (
            <span className={badgeClass.join(' ')}>
                {props.items.badge.title}
            </span>
        );
    }
    return sidebarBadges;
};

export default sidebarBadge;