import React, { useContext } from "react";
import { Row, Col, Card, Table } from 'react-bootstrap';
import { Sparklines, SparklinesBars, SparklinesLine } from 'react-sparklines';
import Humanize from "humanize-plus";
import { useDispatch, useSelector } from 'react-redux';

import $ from 'jquery';

//Datatable Imports
import 'datatables.net-bs4'
import 'datatables.net-bs4/css/dataTables.bootstrap4.css';
//Plugins
import 'datatables.net-buttons/js/dataTables.buttons.js'
import 'datatables.net-buttons/js/buttons.colVis.min.js'
import 'datatables.net-buttons/js/buttons.html5.js'
import 'datatables.net-buttons/js/buttons.print.js'
import 'datatables.net-colreorder-bs4'
import 'datatables.net-searchpanes-bs4'
import 'datatables.net-searchbuilder-bs4'
import 'datatables.net-buttons-bs4'
import 'datatables.net-select-bs4'
import 'datatables.net-select-bs4/css/select.bootstrap4.min.css'
import 'datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css'
import 'datatables.net-searchpanes-bs4/css/searchPanes.bootstrap4.min.css'
import 'datatables.net-searchbuilder-bs4/css/searchBuilder.bootstrap4.min.css'
import 'datatables.net-colreorder-bs4/css/colReorder.bootstrap4.min.css'

import * as actionTypes from "_actions";
import { alertActions } from '../../_actions/alert.actions';
import { connect } from "react-redux";
import windowSize from "react-window-size";
import { history } from '_helpers';

window.$ = window.jQuery = $;

class UserTable extends React.Component {
    constructor(props) {
        super(props);
        this.tableId = this.props.dataTableId
    }

    componentDidMount() {
        $('#' + this.tableId).DataTable({
            data: this.props.data,
            processing: true,
            columns: this.props.columns,
            scrollX: true,
            retrieve: true,
            responsive: true,
            buttons: [
                // {
                //     text: 'File Browser',
                //     action: function (e, dt, node, config) {
                //         history.push("/dashboard/filemanager");
                //     }
                // },
                //'searchPanes', 'colvis', 'copy', 'csv', 'excel', 'pdf', 'print',
            ],
            //dom: 'BQlfrtip',
            //searchPanes: {
            //    cascadePanes: true,
            //    viewTotal: true
            //},
            //colReorder: true
        });
    }

    componentWillUnmount() {
        $('#' + this.tableId)
            .find('table')
            .DataTable()
            .destroy(true);

        // $('#wranglerTable').wrap("<div class='scrolledTable'></div>")
    }

    shouldComponentUpdate() {
        return true;
    }

    render() {
        let theme = 'table'
        if (!this.props.isDarkMode) {
            theme += '-dark'
        }
        return (
            <Table id={this.tableId} className={theme}></Table>
        )
    }


}


const mapStateToProps = state => {
    return {
        isDarkMode: state.navigation.isDarkMode,
        user: state.authentication.user
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onDarkMode: () => dispatch({ type: actionTypes.TOGGLE_DARK_MODE }),
        alertError: (msg) => dispatch(alertActions.error(msg))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(UserTable));
