import React from 'react';
import { Button, Form } from 'react-bootstrap';

function EditableRow({roles, editFormData, handleEditFormChange, handleCancelClick}) {
    return (
        <tr>
        <td>
            <Form.Control
				style={{ width: 60 }}
                type="text"
                required="required"
                name="id"
                value={editFormData.id}
            ></Form.Control>
        </td>
        <td>
            <Form.Control
				style={{ width: 380 }}
                type="text"
                required="required"
                placeholder="Enter a name..."
                name="email"
                value={editFormData.email}
                onChange={handleEditFormChange}
            ></Form.Control>
        </td>
        <td>
            <Form.Control
				style={{ width: 300 }}
                type="text"
                required="required"
                placeholder="Enter a user full name..."
                name="full_name"
                value={editFormData.full_name}
                onChange={handleEditFormChange}
            ></Form.Control>
        </td>
        <td>
            <select
                type="text"
                name="role_name"
                required="required"
                placeholder="false"
                value={editFormData.role_name}
                onChange={handleEditFormChange}>
				{roles.map((role) => (
                    <option>{role.name}</option>
				))}
            </select>
        </td>
        <td>
            <select
                type="bool"
                name="is_superuser"
                required="required"
                placeholder="false"
                value={editFormData.is_superuser}
                onChange={handleEditFormChange}>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        </td>
        <td>
            <select
                type="bool"
                name="is_active"
                required="required"
                placeholder="false"
                value={editFormData.is_active}
                onChange={handleEditFormChange}>
                <option value="true">True</option>
                <option value="false">False</option>
            </select>
        </td>
        <td>
            <Button type="submit">Save</Button>
            <Button type="button" onClick={handleCancelClick}>Cancel</Button>
        </td>
        </tr>
    )
}

export default EditableRow;
