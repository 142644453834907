import React, {useState} from 'react';
import { Modal, Row, Col} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Aux from "components/utils/_Aux";
import * as actionTypes from "_actions";
import "./index.css";
import * as Icon from 'react-icons/fi';
import 'font-awesome/css/font-awesome.min.css';
import { Document, Page, Outline } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default function NavRight(props) {

    const user = useSelector(state => state.authentication.user);
    const dispatch = useDispatch();

    const onNeedHelp = () => { dispatch({ type: actionTypes.NEED_HELP })}
    const logout = () => {window.location.href = "/login"}

    // Modal Properties
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    // React PDF Properties
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => {setNumPages(numPages)}

    const changePage = (offset) => {setPageNumber(prevPageNumber => prevPageNumber + offset)}

    const previousPage = () => {changePage(-1)}

    const nextPage = () => {changePage(1)}

    const onItemClick = ({ pageNumber: itemPageNumber }) => {setPageNumber(itemPageNumber)}

    return (
        <Aux>
            <ul className="navbar-nav ml-auto">
                <li>Hi <b style={{ textTransform: "capitalize" }}>{user ? user.full_name : ""}</b>!</li>
                <li className="logout"><a href={"#!"} onClick={logout}><Icon.FiPower title={"Sign out"} /></a></li>
                <li className="help"><a href={"#!"} onClick={onNeedHelp}><Icon.FiHelpCircle title={"Help"} /></a></li>
                <li className="documentation"><a href={"#!"} onClick={handleShow}><Icon.FiBook title={"Documentation"} /></a></li>
            </ul>

            <Modal show={show} onHide={handleClose}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter">
                <Modal.Header closeButton>
                    <Modal.Title>Documentation &nbsp; <a className="clickable-link-dark" href="/dashboard.pdf" download><Icon.FiDownload title={"Download PDF"} /></a></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Document
                            file={"/dashboard.pdf"}
                            onLoadSuccess={onDocumentLoadSuccess}>
                             <Row>
                                <Col style={{overflowY:"scroll", height:"800px"}}><Outline onItemClick={onItemClick}/></Col>
                                <Col style={{height: "1000px", marginTop: "-0px"}}>
                                    <Page pageNumber={pageNumber} style={{marginTop:"250px"}}/>
                                    <div>
                                        <p>
                                            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
                                        </p>
                                        <button
                                            type="button"
                                            disabled={pageNumber <= 1}
                                            onClick={previousPage}>
                                            Previous
                                        </button>
                                        <button
                                            type="button"
                                            disabled={pageNumber >= numPages}
                                            onClick={nextPage}>
                                            Next
                                        </button>
                                    </div></Col>
                             </Row>
                        </Document>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </Aux>
    )
}
