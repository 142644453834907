export default [
    // {
    //     selector: ".nav-item-data_wrangler",
    //     content: "Interactive tool for data cleaning and transformation. Spend less time formatting and more time analyzing your data"
    // },
    // {
    //     selector: ".dataTables_scroll",
    //     content: "Paginated data table. Supports vertical and horizontal scrolling"
    // },
    // {
    //     selector: ".pagination",
    //     content: "The content is rendered in pages. The pagination navigator allows you to navigate between pages. This nehances user experience and improves performance of the application"
    // },
    // {
    //     selector: ".dataTables_length label",
    //     content: "Select number of rows to be shown in a single page"
    // },
    // {
    //     selector: ".dataTables_filter label",
    //     content: "Search for content in the table",
    //     // stepInteraction: true,
    //     action: node => {
    //         node.focus()
    //     },
    // },
    // {
    //     selector: ".sorting_asc",
    //     content: "Sort the column in ascending or descending order"
    // },
    // {
    //     selector: ".dataTables_scroll",
    //     content: "Paginated data table. Supports vertical and horizontal scrolling"
    // },
    // {
    //     selector: ".dataTables_scroll",
    //     content: "Paginated data table. Supports vertical and horizontal scrolling"
    // },
    {
        selector: ".tab-content div div button",
        content: "Apply filters. Click on the button to enable advanced filter view.",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },
    //Tour for search pane
    // {
    //     selector: ".tab-content div div div div div",
    //     content: "Apply filters. Click on the button to enable advanced filter view.",
    //     action: node => {
    //         node.focus()
    //     },
    // },

    {
        selector: ".tab-content div div div div",
        content: "Select/Discard columns",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },

    {
        selector: ".tab-content div div div button:nth-child(3)",
        content: "Copy filtered rows onto clipboard",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },

     {
        selector: ".tab-content div div div button:nth-child(4)",
        content: "Download filtered rows as a CSV File",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },
     {
        selector: ".tab-content div div div button:nth-child(5)",
        content: "Print filtered rows as a PDF File",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },
     {
        selector: ".dtsb-searchBuilder",
        content: "Advanced conditional (AND / OR) filtering with support for chaining of conditions",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },

]