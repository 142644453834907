import {getLocalToken, handleResponse} from "./utils.service";
import {sessionApi, userApi} from "../api";

export const getSessions = (skip=0, limit=2000) => {
    return sessionApi.getSessions(getLocalToken(), skip, limit).then(handleResponse).catch(handleResponse);
}

export const getExperiments = () => {
    return userApi.getAllExpriments(getLocalToken()).then(handleResponse).catch(handleResponse);
}

export const getExperimentFrame = (exp_id) => {
    return userApi.getExperimentFrame(exp_id, getLocalToken()).then(handleResponse).catch(handleResponse);
}

export const getImageForFrame = (image_link) => {
    console.log('here')
    console.log(image_link)
    return userApi.getImage(image_link, getLocalToken()).then(handleResponse).catch(handleResponse);
}
