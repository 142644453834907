import Index from "components/utils/logo";
import config from "config";
import React from "react";

function ProjectDescription() {
    return (
        <div className="auth-wrapper">
            <div className="auth-content" style={{width: "540px"}}>
                <div className="card" style={{ minHeight: "500px", maxHeight: "800px"}}>
                    <div className="card-body text-center">
                        <div className="row">
                            <div className="col-md-1">
                                <Index app={config.project} text={config.brandLetters}/>
                            </div>
                            <div className="col-md-10">
                                <h3 className="mb-4" style={{
                                    'textAlign': 'center',
                                    'textTransform': "uppercase",
                                    'letterSpacing': "0.1em"
                                }}>
                                    {config.brandTitle}</h3>
                            </div>
                        </div>
                        <p style={{"textAlign": "justify", "paddingTop": "10px", lineHeight:"1.8em"}}
                            dangerouslySetInnerHTML={{ __html:config.description}}>
                        </p>
                        {/* <img src={config.logoImgPath} width="50%" height="30%" /> */}
                    </div>

                </div>
            </div>
        </div>  
    )
}

export {ProjectDescription};
