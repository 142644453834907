import React from 'react';
import {render} from 'react-dom';
import {Provider} from 'react-redux';
import {store} from '_helpers';
import {App} from 'components/App';
import {Users} from 'components/Users';
import {BrowserRouter} from 'react-router-dom';
import {ToastProvider} from 'react-toast-notifications'
import assignPrototypes from "prototypes";
// setup fake backend
// import {configureFakeBackend} from './_helpers';
//require('dotenv').config()

// configureFakeBackend();
assignPrototypes();
render(
    <Provider store={store}>
        <BrowserRouter basename={'/dashboard/'}>
            <ToastProvider>
                <App/>
            </ToastProvider>
        </BrowserRouter>
    </Provider>,
    document.getElementById('app')
);
