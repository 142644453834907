import React from 'react';

const tour_data_visualizer = [{
    selector: ".b-brand",
    content: "Welcome to ARL Dashboard. This tour guide is designed to demonstrate the features of the platform"
},
{
    selector: ".full-screen",
    content: "Enable/disable full screen mode. Provides desktop application experience"
},
{
    selector: ".dark-mode",
    content: "Turn on/off dark mode. Easy on eyes!"
},
{
    selector: ".logout",
    content: "Sign out of the platform"
},
{
    selector: ".help",
    content: "Page tour. Extensive guide to help user navigate / utilize the content on the page"
},
{
    selector: ".settings",
    content: "Platform configuration and User preferences"
},
{
    selector: ".navbar-content",
    content: "This is sidebar. Our platform's navigator"
},
{
    selector: ".mobile-menu",
    content: "Collapse/Expand side-bar for desktop experience"
},
{
        selector: ".nav-item-dataVisualizer2",
        content: "Makes the data more natural for the human mind to comprehend and therefore makes it easier to identify trends, patterns, and outliers within large data sets."
    },


]

export default tour_data_visualizer