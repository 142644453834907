import React, { Component, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import windowSize from 'react-window-size';
import { Modal, Container, Row, Col, } from 'react-bootstrap';
import Aux from "components/utils/_Aux";
import NavGroup from 'components/Navigation/Sidebar/SidebarGroup';
import * as actionTypes from "_actions";
import * as Icon from 'react-icons/fi';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

export default function NavContent(props) {
    const state = {
        scrollWidth: 0,
        prevDisable: true,
        nextDisable: false
    };

    const dispatch = useDispatch()

    const scrollPrevHandler = () => {
        const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;

        let scrollWidth = this.state.scrollWidth - wrapperWidth;
        if (scrollWidth < 0) {
            this.setState({ scrollWidth: 0, prevDisable: true, nextDisable: false });
        } else {
            this.setState({ scrollWidth: scrollWidth, prevDisable: false });
        }
    };

    const scrollNextHandler = () => {
        const wrapperWidth = document.getElementById('sidenav-wrapper').clientWidth;
        const contentWidth = document.getElementById('sidenav-horizontal').clientWidth;

        let scrollWidth = this.state.scrollWidth + (wrapperWidth - 80);
        if (scrollWidth > (contentWidth - wrapperWidth)) {
            scrollWidth = contentWidth - wrapperWidth + 80;
            this.setState({ scrollWidth: scrollWidth, prevDisable: false, nextDisable: true });
        } else {
            this.setState({ scrollWidth: scrollWidth, prevDisable: false });
        }
    };

    const navItems = props.navigation.map(item => {
        switch (item.type) {
            case 'group':
                return <NavGroup layout={props.layout} key={item.id} group={item} />;
            default:
                return false;
        }
    }
    );

    const handleShow = () => {
        dispatch({ type: actionTypes.ENABLE_FULL_SCREEN_NAVIGATION_REDUCER, url: '/MIL-PRF-32662.pdf', outline:false });
    };

    let mainContent = '';


    if (props.layout === 'horizontal') {
        let prevClass = ['sidenav-horizontal-prev'];
        if (state.prevDisable) {
            prevClass = [...prevClass, 'disabled'];
        }
        let nextClass = ['sidenav-horizontal-next'];
        if (state.nextDisable) {
            nextClass = [...nextClass, 'disabled'];
        }

        mainContent = (
            <div className="navbar-content sidenav-horizontal" id="layout-sidenav">
                <a href="#!" className={prevClass.join(' ')} onClick={scrollPrevHandler}><span /></a>
                <div id="sidenav-wrapper" className="sidenav-horizontal-wrapper">
                    <ul id="sidenav-horizontal" className="nav pcoded-inner-navbar sidenav-inner" onMouseLeave={props.onNavContentLeave} style={{ marginLeft: '-' + state.scrollWidth + 'px' }}>
                        {navItems}
                    </ul>
                </div>
                <a href="#!" className={nextClass.join(' ')} onClick={scrollNextHandler}><span /></a>
            </div>
        );
    } else {
        mainContent = (
            <div className="navbar-content datta-scroll">
                <PerfectScrollbar>
                    {/* <br />
                    <Container>
                        <Row>
                            <Col lg="8" md="8">
                                <h6 className="nav-item pcoded-menu-caption"> MIL - PRF - 32662 STD</h6>
                            </Col>
                            <Col lg="1" md="1"><a className="clickable-link-dark" href="/MIL-PRF-32662.pdf" download><Icon.FiDownload title={"Download PDF"} /></a></Col>
                            <Col lg="1" md="1"><a className="clickable-link-dark" href={"#!"} onClick={handleShow} > < Icon.FiBook title={"View PDF"} /></a></Col>
                        </Row>
                    </Container> */}

                    <ul className="nav pcoded-inner-navbar">
                        {navItems}
                    </ul>
                </PerfectScrollbar>
            </div>
        );
    }


    return (
        <Aux>
            {mainContent}
        </Aux>
    );

}

// const mapStateToProps = state => {
//     return {
//         layout: state.layout,
//         collapseMenu: state.collapseMenu,
//     }
// };

// const mapDispatchToProps = dispatch => {
//     return {
//         onNavContentLeave: () => dispatch({ type: actionTypes.NAV_CONTENT_LEAVE }),
//     }
// };

// export default withRouter(connect(mapStateToProps, mapDispatchToProps)(windowSize(NavContent)));
