import config from "../config";
import {saveLocalToken, authHeaders, saveUser, removeUser, removeLocalToken, getLocalToken, handleResponse} from "./utils.service";
import {roleApi} from "../api";


export const roleService = {
    createRole,
    getAll,
    update,
    remove: remove
};

function createRole(role) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }

    return roleApi.createRole(getLocalToken(), role).
        then(handleResponse).
        catch(handleResponse);
}

function getAll(skip=0, limit=100) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }

    return roleApi.getRoles(getLocalToken(), skip, limit).then(handleResponse).catch(handleResponse);
}

function update(data) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }

    return roleApi.updateRole(getLocalToken(), data.id, data).then(handleResponse);
}

function remove(data) {
    return roleApi.removeRole(getLocalToken(), data).
        then(handleResponse).
        catch(handleResponse);
}

