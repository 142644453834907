import projectConfig from "projectConfig";

export default {
    ...projectConfig,
    apiUrl: process.env.REACT_APP_ENV === "development" ? 'http://' + process.env.REACT_APP_DOMAIN_DEV : 'https://' + process.env.REACT_APP_DOMAIN_PROD,
    apiUrlFe: process.env.REACT_APP_ENV === "development" ? 'http://' + process.env.REACT_APP_DOMAIN_DEV_FE : 'https://' + process.env.REACT_APP_DOMAIN_PROD,
    webSocketUrl: process.env.REACT_APP_ENV === "development" ? 'ws://' + process.env.REACT_APP_DOMAIN_DEV : 'wss://' + process.env.REACT_APP_DOMAIN_PROD,
    defaultPath: '/dashboard/',
    basename: '/dashboard/',
    layout: 'vertical',
    preLayout: null,
    collapseMenu: false,
    layoutType: 'menu-light',
    navIconColor: false,
    headerBackColor: 'header-default',
    navBackColor: 'navbar-default',
    navBrandColor: 'brand-default',
    navBackImage: false,
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: true,
    boxLayout: false,
    navDropdownIcon: 'style1',
    navListIcon: 'style1',
    navActiveListColor: 'active-default',
    navListTitleColor: 'title-default',
    navListTitleHide: false,
    configBlock: false,
    layout6Background: 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
    layout6BackSize: '',
};
