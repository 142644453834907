import React from 'react';
import Aux from "components/utils/_Aux";
import config from "config";
import "components/utils/logo/logo.css";

const navLogo = (props) => {
    let toggleClass = ['mobile-menu'];
    if (props.collapseMenu) {
        toggleClass = [...toggleClass, 'on'];
    }
    return (
        <Aux>
            <div className="navbar-brand header-logo">
                <a href="/dashboard" className="b-brand">
                    <div className={"b-bg logo-" + config.project}>
                        {config.brandLetters}
                    </div>
                    <span className="b-title" style={{padingLeft:"10%"}}>{config.brandTitle}</span>
                    <br/>
                    <span className="b-title" style={{marginLeft:"-50px", paddingTop:"25%", fontSize:"x-small"}}>BETA {process.env.REACT_APP_VERSION}</span>
                </a>
                <a href="#!" title={"Hide Sidebar"} className={toggleClass.join(' ')} id="mobile-collapse" onClick={props.onToggleNavigation}><span /></a>
            </div>
        </Aux>
    );
};

export default navLogo;
