import React from "react";

export default function Page404(props){
    return(
        <div className="auth-wrapper offline">
            <div className="text-center">
                <h1 className="mb-4">OFFLINE</h1>
                <h5 className="text-muted mb-4">Oops! Website Is Temporarily Offline</h5>
                <form action="/">
                    <button className="btn btn-primary mb-4"><i className="feather icon-home"></i>Back to Home</button>
                </form>
            </div>
        </div>
    )
}
