import React, { Component } from 'react';


import "./logo.css"
import config from "config";

class Index extends Component {

    render() {
        const avatarWidth = this.props.width || 100,
            avatarHeight = this.props.height || 100,
            style = {
                // 'background-color': colors[colorIndex],
                'width': avatarWidth,
                'height': avatarHeight,
                'font': avatarWidth / 2 + "px Arial",
                'color': '#FFF',
                'textAlign': 'center',
                'lineHeight': avatarHeight + 'px',
                'borderRadius': '10%'
            }
        // style = {
        //     // 'background-color': colors[colorIndex],
        //     top:this.props.top ||  "0%",
        //     left:this.props.left || "0%",
        //     'width': avatarWidth,
        //     'height': avatarHeight,
        //     'font': avatarWidth / 2 + "px Arial",
        //     'color': '#FFF',
        //     'textAlign': 'center',
        //     'lineHeight': avatarHeight + 'px',
        //     'borderRadius': '10%',
        //     // 'display':"inline-flex",
        //     "alignItem":"center",
        //     // "justifyContent":"center",
        //     "position":"absolute",
        //     // "marginLeft":"15px"
        //
        // }
        return (

            <div className={"logo " + "logo-" + config.project} width={avatarWidth} height={avatarHeight} style={style}>{config.brandLetters}</div>
        )
    }
};
export default Index;