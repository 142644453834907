import {
    fileConstants
} from '_constants';

const initialState = {
    dataWranglerConfig:{
        fileId: null,
        data: null,
        columns: null,
        summary: null
    },
    dataVisualizerConfig: {
        fileId:null,
        dataSource: {},
        data: [],
        layout: {},
        frames: [],
        currentMockIndex: -1,
        mocks: [],
        fileDetails: {
            file_name: null,
            file_size: null,
            file_type: null,
            uploaded_date: null,
        }
    }
};

export function files(state = initialState, action) {
    switch (action.type) {
        case fileConstants.UPDATE_DATA_VISUALIZER_PLOTLY_CONFIG:
            return {
                ...state,
                dataVisualizerConfig: {
                    ...state.dataVisualizerConfig,
                    ...action.value
                }
            }
        case fileConstants.UPDATE_DATA_VISUALIZER_CONFIG:
            return {
                ...state,
                dataVisualizerConfig: {
                    ...state.dataVisualizerConfig,
                    ...action.value
                }
            }
        case fileConstants.UPDATE_DATA_VISUALIZER_MOCK_CONFIG:
            return {
                ...state,
                dataVisualizerConfig: {
                    ...state.dataVisualizerConfig,
                    ...action.value
                }
            }
        case fileConstants.UPDATE_DATA_WRANGLER_DATA_TABLE_CONFIG:
            return {
                ...state,
                dataWranglerConfig:{
                    fileId: action.fileId,
                    data:action.data,
                    columns:action.columns,
                    fileDetails:action.fileDetails
                }
            }
        case fileConstants.UPDATE_DATA_WRANGLER_SUMMARY_CONFIG:
            return {
                ...state,
                dataWranglerConfig: {
                    ...state.dataWranglerConfig,
                    fileId: action.fileId,
                    summary: action.summary,
                }
            }
        case fileConstants.DATAWRANGLER_FILE:
            return {
                ...state,
                datawranglerFileId: action.file_id,
                datawranglerFileName: action.file_name

            };
         case fileConstants.VISUALIZER_FILE:
             return {
                 ...state,
                 visualizerFileId: action.file_id,
                 visualizerFileName: action.file_name
             };
        default:
            return state
    }
}
