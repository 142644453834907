import React from 'react';

export default [

    {
        selector: ".nav-item-file_manager",
        content: ({ goTo, inDOM }) => (
            <div>
                <h5>File Manager</h5>
                <br />
                <p>Browse for available files on the platform. Supports file upload, filter, search and deletion operations</p>
            </div>
        ),
    },

    {
        selector: ".card",
        content: "Click to upload files",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },

    {
        selector: ".card-body",
        content: "Either drag and drop files or click on upload files to upload files to the platform. Support multi-file uploads. Select one/multiple CSV files to upload.",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },

    {
        selector: ".dzu-previewContainer",
        content: "Displays filename, size of the file and a progress bar indicating upload progress.",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },
    {
        selector: ".dzu-submitButton",
        content: "Once done, click submit to view the updated list of available files",
        stepInteraction: true,
        action: node => {
            node.focus()
        },
    },

    {
        selector: ".file-browser",
        stepInteraction: true,
        content: ({ goTo, inDOM }) => (
            <div>
                <h5>File Browser</h5>
                <br />
                <p>Browse for available files on the platform. Supports search, filter table operations. Available actions are - Open the file in Data wrangler / Data Visualizer and file deletion</p>
            </div>
        ),
        action: node => {
            node.focus()
        },
    }
]
