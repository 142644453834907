import React, {Component} from 'react';
import {connect} from 'react-redux';
//import {Dropdown} from 'react-bootstrap';
import windowSize from 'react-window-size';
import NavSearch from './NavSearch/index.js';
import Aux from "components/utils/_Aux";
import * as actionTypes from "_actions";
import config from "../../../../config.js";
import * as Icon from 'react-icons/fi';
import {getUser, saveUser} from "../../../../_services/utils.service"
import {userService} from "../../../../_services/user.service"
class NavLeft extends Component {

    constructor() {
        super();
        this.themes = ['/assets/dark.css', ''];
        const head = document.body.parentElement.firstElementChild;
        this.link = document.createElement('link');
        this.link.setAttribute('href', config.apiUrlFe + this.themes[0]);
        this.link.setAttribute('rel', "stylesheet"); // s
        this.link.setAttribute('id', this.themes[0]); // set id so we can remove it later
        head.appendChild(this.link);
        this.toggleDarkMode = this.toggleDarkMode.bind(this);
    }

    toggleDarkMode(){
        let user = getUser();
        user.dark_mode = this.props.isDarkMode;
        userService.update(user);
        this.props.onDarkMode();
    }

    render() {
        let iconFullScreen = (this.props.isFullScreen) ? <Icon.FiMinimize title={"Full Screen"} /> : <Icon.FiMaximize title={"Full Screen"} />;
        let iconDarkMode = (this.props.isDarkMode) ? <Icon.FiMoon title={"Dark Mode"}/> : <Icon.FiSun title={"Dark Mode"}/>;

        let navItemClass = ['nav-item'];
        if (this.props.windowWidth <= 575) {
            navItemClass = [...navItemClass, 'd-none'];
        }
        //console.log(navItemClass)
        let dropdownRightAlign = false;
        //console.log(dropdownRightAlign)
        if (this.props.rtlLayout) {
            dropdownRightAlign = true;
        }

        if (this.props.isDarkMode){
            this.link.setAttribute('href', config.apiUrlFe+"/" +  this.themes[1]);
        }
        else{
            this.link.setAttribute('href', config.apiUrlFe+"/" + this.themes[0]);
        }

        return (
            <Aux>
                <ul className="navbar-nav mr-auto">
                    <li><a href={"#!"} className="full-screen" onClick={this.props.onFullScreen}>{iconFullScreen}</a></li>
                    <li><a href={"#!"} className="dark-mode" onClick={this.toggleDarkMode}>{iconDarkMode}</a></li>
                    {/*<li className={navItemClass.join(' ')}>*/}
                    {/*    <Dropdown alignRight={dropdownRightAlign}>*/}
                    {/*        <Dropdown.Toggle variant={'link'} id="dropdown-basic">*/}
                    {/*            Dropdown*/}
                    {/*        </Dropdown.Toggle>*/}
                    {/*        <ul>*/}
                    {/*            <Dropdown.Menu>*/}
                    {/*                <li><a className="dropdown-item" href={"#!"}>Action</a></li>*/}
                    {/*                <li><a className="dropdown-item" href={"#!"}>Another action</a></li>*/}
                    {/*                <li><a className="dropdown-item" href={"#!"}>Something else here</a></li>*/}
                    {/*            </Dropdown.Menu>*/}
                    {/*        </ul>*/}
                    {/*    </Dropdown>*/}
                    {/*</li>*/}
                    <li className="nav-item"><NavSearch/></li>
                </ul>
            </Aux>
        );
    }
}

const mapStateToProps = state => {
    return {
        isDarkMode:state.navigation.isDarkMode,
        isFullScreen: state.navigation.isFullScreen,
        rtlLayout: state.navigation.rtlLayout
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onFullScreen: () => dispatch({type: actionTypes.FULL_SCREEN}),
        onDarkMode: () => dispatch({type:actionTypes.TOGGLE_DARK_MODE})
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(windowSize(NavLeft));
