import config from "../config";
import {saveLocalToken, authHeaders, saveUser, removeUser, removeLocalToken, getLocalToken, handleResponse} from "./utils.service";
import {userApi} from "../api";

export const userService = {
    login,
    logout,
    register,
    createUser,
    getAll,
    getById,
    update,
    updateUser,
    delete: _delete,
    remove: remove
};


function login(username, password) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    return userApi.logInGetToken(username, password)
        .then(handleResponse)
        .then(response => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            const token = response.data.access_token;
            saveLocalToken(token)
            return token
        })
        .then(userApi.getMe)
        .then(response => {
            const user = response.data;
            saveUser(user);
            return user
        }).catch(handleResponse);
}

function createUser(user) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }

    return userApi.createUser(getLocalToken(), user).
        then(handleResponse).
        catch(handleResponse);
}

function register(user) {
    return userApi.createUserOpen(user).then(handleResponse).catch(handleResponse);
}

function logout() {
    // remove user and token from local storage to log user out
    removeUser();
    removeLocalToken();
}

function getAll(skip=0, limit=100) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }
    return userApi.getUsers(getLocalToken(), skip, limit).then(handleResponse).catch(handleResponse);
}

function getById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeaders()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}


function update(user) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }

    saveUser(user);
    return userApi.updateUserPreferences(getLocalToken(), user.id, user).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    // const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    // if (parseJwt.exp * 1000 < Date.now()) {
    //     removeUser();
    //     removeLocalToken();
    //     window.location.href = "/login"
    //   }

    const requestOptions = {
        method: 'DELETE',
        headers: authHeaders()
    };

    return fetch(`${config.apiUrl}/users/${id}`, requestOptions).then(handleResponse);
}

function remove(data) {
    return userApi.removeUser(getLocalToken(), data).
        then(handleResponse).
        catch(handleResponse);
}

function updateUser(user) {
    return userApi.updateUserPreferences(getLocalToken(), user.id, user).then(handleResponse);
}

