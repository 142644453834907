import {getLocalToken, handleResponse} from "./utils.service";
import {taskApi} from "../api";

export const createTask = (task) => {
    return taskApi.createTask(getLocalToken(), task).
        then(handleResponse).
        catch(handleResponse);
}

export const getTasks = () => {
    return taskApi.getTasks(getLocalToken()).then(handleResponse).catch(handleResponse);
}

export const completeTask = (id) => {
    return taskApi.completeTask(getLocalToken(), id).then(handleResponse);
}

export const repeatTask = (id) => {
    return taskApi.repeatTask(getLocalToken(), id).then(handleResponse);
}

export const deleteTask = (id) => {
    return taskApi.deleteTask(getLocalToken(), id).then(handleResponse);
}

// export const taskService = {
//     createTask,
//     getTasks,
//     completeTask,
//     repeatTask,
//     deleteTask,
// };
