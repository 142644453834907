import React from 'react';
import $ from 'jquery';
import WebsocketExample from "components/WebsocketExample/WebsocketExample";
import { useSelector } from "react-redux";
const Users = React.lazy(() =>
    import ('components/Users')
);

window.jQuery = $;
window.$ = $;
global.jQuery = $;



const DashboardDefault = React.lazy(() => import('components/Dashboard/DashboardCorrosion'));
const DataWrangler = React.lazy(() => import('components/DataWrangler/DataWrangler'));
//const DataVisualizer = React.lazy(() => import('components/DataVisualizer/DataVisualizer'));
const DataVisualizerV2 = React.lazy(() => import('components/DataVisualizer/DataVisualizerV2'));
const FileManager = React.lazy(() => import('components/FileManager/FileManager'));
const ChartTemplate = React.lazy(() => import('components/ChartTemplate/ChartTemplate'));

//test pasge
const DeepLearning2 = React.lazy(() => import('components/DeepLearning2/DeepLearning2'));

// const DataSlider = React.lazy(() => import('./_layout/imageSlider/index'));
const DataSummary = React.lazy(() => import('./_layout/dataSummary/index'));
const ExperimentView = React.lazy(() => import('components/ExperimentView'));
const ExperimentAnalysis = React.lazy(() => import('components/ExperimentAnalysis/ExperimentAnalysis'));
const ExperimentSearch = React.lazy(() => import('components/ExperimentSearch'));
const DeepLearning = React.lazy(() => import('components/DeepLearning/DeepLearning'));
const SessionView = React.lazy(() => import('components/SessionView/SessionView'));

// const WebSocketExample = React.lazy(() => import('./_layout/websocket/indexv1'));
//
// const UIBasicButton = React.lazy(() => import('./Demo/UIElements/Basic/Button'));
// const UIBasicBadges = React.lazy(() => import('./Demo/UIElements/Basic/Badges'));
// const UIBasicBreadcrumbPagination = React.lazy(() => import('./Demo/UIElements/Basic/BreadcrumbPagination'));
//
// const UIBasicCollapse = React.lazy(() => import('./Demo/UIElements/Basic/Collapse'));
// const UIBasicTabsPills = React.lazy(() => import('./Demo/UIElements/Basic/TabsPills'));
// const UIBasicBasicTypography = React.lazy(() => import('./Demo/UIElements/Basic/Typography'));
//
// const FormsElements = React.lazy(() => import('./Demo/Forms/FormsElements'));
//
// const BootstrapTable = React.lazy(() => import('./Demo/Tables/BootstrapTable'));
//
// const Nvd3Chart = React.lazy(() => import('./Demo/Charts/Nvd3Chart/index'));
//
// const GoogleMap = React.lazy(() => import('./Demo/Maps/GoogleMap/index'));
//
// const OtherSamplePage = React.lazy(() => import('./Demo/Other/SamplePage'));
// const OtherDocs = React.lazy(() => import('./Demo/Other/Docs'));


const routes = [
    // { path: '/dashboard/default', exact: true, name: 'Default', component: DashboardDefault },
    {path: '/dashboard', exact: true, name: 'Default', component: DashboardDefault},
    {path: '/dashboard/wrangler', exact: true, name: 'Data Wrangler', component: DataWrangler},
    {path: '/dashboard/visualizer', exact: true, name: 'Data Visualizer', component: DataVisualizerV2},
    {path: '/dashboard/template', exact: true, name: 'Chart Template', component: ChartTemplate},
    {path: '/dashboard/websocket', exact: true, name: 'Web Socket', component: WebsocketExample},
    {path: '/dashboard/filemanager', exact: true, name: 'File Manager', component: FileManager},
    // {path: '/dashboard/imageSlider', exact: true, name: 'Image Slider', component: DataSlider},
    { path: '/dashboard/dataSummary', exact: true, name: 'Data Summary', component: DataSummary },
    { path: '/dashboard/experimentView', exact: true, name: 'Experiment View', component: ExperimentView },
     //{ path: '/dashboard/dataVisualizer2', exact: true, name: 'Data Visualizer2', component: DataVisualizer2 },
    { path: '/dashboard/experimentAnalysis', exact: true, name: 'Experiment Analysis', component: ExperimentAnalysis },
    { path: '/dashboard/experimentSearch', exact: true, name: 'Experiment Search', component: ExperimentSearch },
    { path: '/dashboard/deeplearning', exact: true, name: 'Deep Learning', component: DeepLearning},
    //added testpage
    { path: '/dashboard/deeplearning2', exact: true, name: 'deeplearning2', component: DeepLearning2},
    { path: '/dashboard/sessionView', exact: true, name: 'Session View', component: SessionView}


    // { path: '/basic/button', exact: true, name: 'Basic Button', component: UIBasicButton },
    // { path: '/basic/badges', exact: true, name: 'Basic Badges', component: UIBasicBadges },
    // { path: '/basic/breadcrumb-paging', exact: true, name: 'Basic Breadcrumb Pagination', component: UIBasicBreadcrumbPagination },
    // { path: '/basic/collapse', exact: true, name: 'Basic Collapse', component: UIBasicCollapse },
    // { path: '/basic/tabs-pills', exact: true, name: 'Basic Tabs & Pills', component: UIBasicTabsPills },
    // { path: '/basic/typography', exact: true, name: 'Basic Typography', component: UIBasicBasicTypography },
    // { path: '/forms/form-basic', exact: true, name: 'Forms Elements', component: FormsElements },
    // { path: '/tables/bootstrap', exact: true, name: 'Bootstrap Table', component: BootstrapTable },
    // { path: '/charts/nvd3', exact: true, name: 'Nvd3 Chart', component: Nvd3Chart },
    // { path: '/maps/google-map', exact: true, name: 'Google Map', component: GoogleMap },
    // { path: '/sample-page', exact: true, name: 'Sample Page', component: OtherSamplePage },
    // { path: '/docs', exact: true, name: 'Documentation', component: OtherDocs },
];



export default routes;
