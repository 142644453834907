import tour_dashboard from "tours/dashboard";
import tour_data_wrangler from "tours/data_wrangler";
import tour_data_visualizer from "tours/data_visualizer.jsx";
import tour_file_manager from "tours/file_manager.jsx";

export default function (page) {
    if (page.includes('/dashboard/wrangler'))
        return tour_data_wrangler
    else if (page.includes('/dashboard/visualizer'))
        return tour_data_visualizer
    else if (page.includes('/dashboard/filemanager'))
        return tour_file_manager
    else {
        return [
            ...tour_dashboard,
            // ...tour_data_wrangler,
            // ...tour_data_visualizer,
            // ...tour_file_manager
        ]
    }
}
