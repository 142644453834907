import React, {Suspense} from 'react';
import {Link, Switch, Redirect, Route} from 'react-router-dom';
import {connect} from 'react-redux';
// import { FullScreen, useFullScreenHandle } from "react-full-screen";
import {userActions} from '_actions';
import Aux from "components/utils/_Aux";
import windowSize from 'react-window-size';
import Navigation from 'components/Navigation';
import NavBar from 'components/Navigation/NavBar';
import * as actionTypes from "_actions";
import Loader from "components/utils/Loader";
import Breadcrumb from "components/Breadcrumb";
import 'components/HomePage/HomePage.scss';
import routes from "routes";
import $ from 'jquery';
import { getUser } from "../../_services/utils.service";
const Users = React.lazy(() =>
    import ('components/Users')
);
const Roles = React.lazy(() =>
    import ('components/Roles')
);

window.$ = window.jQuery = $;

const userRoute = { path: '/dashboard/users', exact: true, name: 'Users', component: Users };
const roleRoute = { path: '/dashboard/roles', exact: true, name: 'Roles', component: Roles };

class HomePage extends React.Component {

    constructor(props){
        super(props);
        let user = getUser();
        // Enable/Disable dark mode based on user preferences
        user.dark_mode ? this.props.onDarkModeDisable() : this.props.onDarkModeEnable();
    }


    componentDidMount() {
        this.props.getUsers();
    }

    handleDeleteUser(id) {
        return (e) => this.props.deleteUser(id);
    }

    fullScreenExitHandler = () => {
        if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            this.props.onFullScreenExit();
        }
    };

    componentWillMount() {
        if (this.props.windowWidth > 992 && this.props.windowWidth <= 1024 && this.props.layout !== 'horizontal') {
            this.props.onComponentWillMount();
        }
    }

    mobileOutClickHandler() {
        if (this.props.windowWidth < 992 && this.props.collapseMenu) {
            this.props.onComponentWillMount();
        }
    }

    render() {
        const {user, users} = this.props;
        const newRoutes = [...routes];
        if (user && true === user.is_superuser) {
            newRoutes.push(userRoute);
            newRoutes.push(roleRoute);
        }
         
        const menu = newRoutes.map((route, index) => {
            return (route.component) ? (
                <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                        <route.component {...props} />
                    )} />
            ) : (null);
        });

        /* full screen exit call */
        document.addEventListener('fullscreenchange', this.fullScreenExitHandler);
        document.addEventListener('webkitfullscreenchange', this.fullScreenExitHandler);
        document.addEventListener('mozfullscreenchange', this.fullScreenExitHandler);
        document.addEventListener('MSFullscreenChange', this.fullScreenExitHandler);
        return (
            <Aux>
                {/* <FullScreen enabled={this.props.isFullScreen}> */}
                    <Navigation/>
                    <NavBar />
                        <div className="pcoded-main-container" onClick={() => this.mobileOutClickHandler}>
                            <div className="pcoded-wrapper">
                                <div className="pcoded-content">
                                    <div className="pcoded-inner-content">
                                        <Breadcrumb />
                                        <div className="main-body">
                                            <div className="page-wrapper">
                                                <Suspense fallback={<Loader/>}>
                                                    <Switch>
                                                        {menu}
                                                        <Redirect from="/dashboard"  to={this.props.defaultPath} />
                                                    </Switch>
                                                </Suspense>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/*<div className="col-md-6 col-md-offset-3">*/}
                    {/*    <h1>Hi {user.firstName}!</h1>*/}
                    {/*    <p>You're logged in with React!!</p>*/}
                    {/*    <h3>All registered users:</h3>*/}
                    {/*    {users.loading && <em>Loading users...</em>}*/}
                    {/*    {users.error && <span className="text-danger">ERROR: {users.error}</span>}*/}
                    {/*    {users.items &&*/}
                    {/*    <ul>*/}
                    {/*        {users.items.map((user, index) =>*/}
                    {/*            <li key={user.id}>*/}
                    {/*                {user.firstName + ' ' + user.lastName}*/}
                    {/*                {*/}
                    {/*                    user.deleting ? <em> - Deleting...</em>*/}
                    {/*                        : user.deleteError ?*/}
                    {/*                        <span className="text-danger"> - ERROR: {user.deleteError}</span>*/}
                    {/*                        : <span> - <a onClick={this.handleDeleteUser(user.id)}>Delete</a></span>*/}
                    {/*                }*/}
                    {/*            </li>*/}
                    {/*        )}*/}
                    {/*    </ul>*/}
                    {/*    }*/}
                    {/*    <p>*/}
                    {/*        <Link to="/login">Logout</Link>*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    <div id="last-theme"></div>
                {/* </FullScreen> */}
            </Aux>
        );
    }
}

function mapState(state) {
    const {users, authentication} = state;
    const {user} = authentication;
    return {
        user, users,
        defaultPath: state.navigation.defaultPath,
        isFullScreen: state.navigation.isFullScreen,
        collapseMenu: state.navigation.collapseMenu,
        configBlock: state.navigation.configBlock,
        layout: state.navigation.layout
    };
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: userActions.getAll,
        deleteUser: userActions.delete,
        onDarkModeToggle: () => dispatch({ type: actionTypes.TOGGLE_DARK_MODE }),
        onDarkModeEnable: () => dispatch({ type: actionTypes.ENABLE_DARK_MODE }),
        onDarkModeDisable: () => dispatch({ type: actionTypes.DISABLE_DARK_MODE }),
        onFullScreenExit: () => dispatch({type: actionTypes.FULL_SCREEN_EXIT}),
        onComponentWillMount: () => dispatch({type: actionTypes.COLLAPSE_MENU})
    }
}

const connectedHomePage = connect(mapState, mapDispatchToProps)(windowSize(HomePage));
export {connectedHomePage as HomePage};
