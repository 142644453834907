import React, {Suspense, useEffect, useState} from 'react';
import {Router, Route, Switch, Redirect} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {history} from '_helpers';
// import {alertActions} from '_actions';
import {PrivateRoute} from 'components';
import {HomePage} from 'components/HomePage';
import {LoginPage} from 'components/LoginPage';
import {RegisterPage} from 'components/RegisterPage';
import Page404 from 'components/404';
import ScrollToTop from "components/utils/ScrollToTop";
import Loader from "components/utils/Loader";
import Aux from "components/utils/_Aux";
import {ToastProvider, useToasts} from 'react-toast-notifications'
import {alertConstants} from "_constants";
import '@fortawesome/fontawesome-free/css/all.min.css';
import routes from "routes";
import Tour from 'reactour';
import steps from "tours";
import * as actionTypes from "_actions";
import FullScreenPDFViewer from 'components/utils/FullScreenPDFViewer';
import { getUser } from "../../_services/utils.service";


const Roles = React.lazy(() =>
    import ('components/Roles')
);
const Users = React.lazy(() =>
    import ('components/Users')
);

function App() {
    const alert = useSelector(state => state.alert);
    const dispatch = useDispatch();
    const {addToast} = useToasts();
    const navigator = useSelector(state =>state.navigation)
    const userRoute = { path: '/dashboard/users', exact: true, name: 'Users', component: Users };
    const roleRoute = { path: '/dashboard/roles', exact: true, name: 'Roles', component: Roles };

    const [menu, setMenu] = useState();

    useEffect(() => {
        history.listen((location, action) => {
            // clear alert on location change
            // dispatch(alertActions.clear());
        });
        if (alert.message) {
            if (alert.type === alertConstants.SUCCESS)
                addToast(alert.message, {appearance: 'success', autoDismiss: true})
            else if (alert.type === alertConstants.ERROR)
                addToast(alert.message, {appearance: 'error', autoDismiss: true})
        }
        const user = getUser();
        const newRoutes = [...routes];
        console.log('user: ', user);
        if (user && true === user.is_superuser) {
            newRoutes.push(userRoute);
            newRoutes.push(roleRoute);
        }
        console.log("new routes: ", newRoutes);

        const newMenu = newRoutes.map((route, index) => {
            return (route.component) ? (
                <PrivateRoute
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    component={props => (
                        <route.component {...props} />
                    )}/>
            ) : (null);
        });
        console.log("new menu: ", newMenu);
        setMenu(newMenu);
    }, [alert, addToast, dispatch]);

    return (
        <Aux>
            <Router history={history}>
                <ScrollToTop>
                    <Suspense fallback={<Loader/>}>
                        <Router history={history}>
                            <Switch>
                                <PrivateRoute path="/dashboard" component={HomePage}/>
                                {menu}
                                <Route path="/404" component={Page404} />
                                <Route path="/login" component={LoginPage}/>
                                <Route path="/register" component={RegisterPage}/>
                                <Redirect from="*" to="/dashboard"/>
                            </Switch>
                        </Router>
                    </Suspense>
                </ScrollToTop>
            </Router>
            <Tour
                steps={steps(window.location.href)}
                isOpen={navigator.needHelp}
                onRequestClose={() => {dispatch({type: actionTypes.NEED_HELP})}}
                disableFocusLock={true}
            />
            <FullScreenPDFViewer/>
        </Aux>
    );
}

export {App};
