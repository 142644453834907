import React, {useState, useEffect, Fragment} from 'react';
import { useSelector, useDispatch } from "react-redux";
import UserTable from "../UserTable/UserTable";
import { Row, Col, Card, Table, Button, Modal, Form } from 'react-bootstrap';
import { userService, roleService } from '_services';
import { expiredToken } from "_services/utils.service";
import Aux from "components/utils/_Aux";
import FileDetails from "../FileManager/FileDetails";
import { connect } from "react-redux";
import windowSize from "react-window-size";
import * as actionTypes from "_actions";
import { alertActions } from '../../_actions/alert.actions';
import "bootstrap/dist/css/bootstrap.css";
import 'antd/dist/antd.min.css'
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import './index.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import { nanoid } from "nanoid";
import ReadOnlyRow from "./ReadOnlyRow";
import EditableRow from "./EditableRow";
import moke_data from "./mock-data.json";
// import "./User.css";

function Users() {
    const formData = {
    			id: '',
    			email: '',
                password: '',
    			full_name: '',
    			is_superuser: "false",
                is_active: "false",
                role_name: ''
    };
    const user = useSelector(state => state.authentication.user)
	// const [errorMsg, setErrorMsg] = useState('');
    const [theme, setTheme] = useState('')
	const [showPassword, setShowPassWord] = useState("password");
	const [users, setUsers] = useState([]);
	const [roles, setRoles] = useState([]);
	const [defaultRole, setDefaultRole] = useState('WPI');
	const [addFormData, setAddFormData] = useState(formData);
	const [editFormData, setEditFormData] = useState(formData);
	const [editUserId, setEditUserId] = useState(null);
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(10);
	const [show, setShow] = useState(false);
    const dispatch = useDispatch();

    const handleUserFormShow = () => setShow(true);
    const handleUserFormClose = () => setShow(false);

    useEffect(() => {
		expiredToken();

        let resp = roleService.getAll(page * pageSize, pageSize);
        resp.then(
            (value) => {
                setRoles(value.data);
                let defRole = value.data.length > 0 ? value.data[0].name : "WPI";
                console.log("default role: %s", defRole);
                setDefaultRole(defRole);
		        const newFormData = { ...addFormData	};
		        newFormData["role_name"] = defRole;
		        setAddFormData(newFormData);
            },
            (reason) => {
                console.log(reason)
		        confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
            }
        );

        resp = userService.getAll(page * pageSize, pageSize);
        resp.then(
            (value) => {
                setUsers(value.data);
            },
            (reason) => {
                console.log(reason)
		        confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
            }
        );
        
		let theme = 'table'
        if (user.isDarkMode) {
            theme += '-dark'
        }
		setTheme(theme);
    }, []);


	const handlePreviousPage = (event) => {
		event.preventDefault();
        if (page <= 0) {
            return;
        }
        const newPage = page - 1;
        setPage(newPage);
        console.log('current page: %d, users length: %d, pageSize: %d', newPage, users.length, pageSize);
        const resp = userService.getAll(newPage * pageSize, pageSize);
        resp.then(
            (value) => {
                setUsers(value.data);
            },
            (reason) => {
                console.log(reason)
		        confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
            }
        );
	}


	const handleNextPage = (event) => {
		event.preventDefault();
        if (users.length < pageSize) {
            return;
        }
        const newPage = page + 1;
        setPage(newPage);
        console.log('current page: %d, users length: %d, pageSize: %d', newPage, users.length, pageSize);
        const resp = userService.getAll(newPage * pageSize, pageSize);
        resp.then(
            (value) => {
                setUsers(value.data);
            },
            (reason) => {
                console.log(reason)
		        confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
            }
        );
	}

	const handleEditFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;

		const newFormData = { ...editFormData	};
		newFormData[fieldName] = fieldValue;

        console.log('%s: %s', fieldName, fieldValue);
        console.log('new form data: %o', newFormData);
		setEditFormData(newFormData);
	}

	const handleAddFormChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;

		const newFormData = { ...addFormData	};
		newFormData[fieldName] = fieldValue;
        
        console.log('%s: %s', fieldName, fieldValue);
        console.log('new form data: %o', newFormData);
		setAddFormData(newFormData);
	}

	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		const newUser = {
			email: addFormData.email,
            password: addFormData.password,
			full_name: addFormData.full_name,
			is_superuser: addFormData.is_superuser,
			is_active: addFormData.is_active,
			role_name: addFormData.role_name,
		};
        console.log("add user role_name: %o", addFormData.role_name);
        console.log("new user: %o", newUser);
        let resp = userService.createUser(newUser);
        console.log(resp);
        resp.then(
            (value) => {
                console.log(resp)
		        confirmAlert({title: "", message: "Add User Successfully!", buttons: [{label: 'OK'}]});
                userService.getAll(page * pageSize, pageSize).then(
                    (resp) => {
                        setUsers(resp.data);
                    }
                );
            },
            (reason) => {
                console.log(reason)
		        confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
            }
        );
		handleUserFormClose();	
	}

	const handleEditClick = (event, user) => {
		event.preventDefault();
		setEditUserId(user.id);
		const formValues = {
			id: user.id,
			email: user.email,
			full_name: user.full_name,
			role_name: user.role_name,
			is_superuser: user.is_superuser,
			is_active: user.is_active,
			
		}
		setEditFormData(formValues);
	}
	
	const handleCancelClick = () => {
		setEditUserId(null);
	}
	const handleEditFormSubmit = (event) => {
		event.preventDefault();
        console.log('Save Data Action');
	
		confirmAlert(
		{
			title: "",
			message: "Click Yes to Save Record!",
			buttons: [
				{
					label: 'Yes',
					onClick: () => {
                        const editedUser = users.find(
		                	(contact)=> {
                                if (contact.id === editUserId) {
                                    return contact.email;
                                }
                            }
		                )
						editedUser.email= editFormData.email;
						editedUser.full_name= editFormData.full_name;
						editedUser.is_superuser= editFormData.is_superuser;
						editedUser.is_active= editFormData.is_active;
						editedUser.role_name= editFormData.role_name;
                        console.log("edited user: %o", editedUser);
                        let resp = userService.updateUser(editedUser);
                        resp.then(
                            (value) => {
		                        confirmAlert({title: "",message: "Update User Successfully!", buttons: [{label: 'OK'}]});
                                let resp = userService.getAll(page * pageSize, pageSize);
                                resp.then(
                                    (resp) => {
                                        setUsers(resp.data);
                                    },
                                    (reason) => {
                                        console.log(reason)
		                                confirmAlert({title: "",message: reason, buttons: [{label: 'OK'}]});
                                    }
                                );
                            },
                            (reason) => {
                                console.log(reason)
		                        confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
                            }
                        );
						setEditUserId(null);
					}
				},
				{
					label: 'No',
					onClick: () => {
						setEditUserId(null);
					}
				},
			]
		}
		);

	}


	const handleDeleteClick = (userId) => {
        console.log('alert delete action!');
		confirmAlert(
			{
				title: "Warning!",
				message: "Click Yes to Delete Record!",
				buttons: [
					{
						label: 'Yes',
						onClick: () => {
		                    const contact = users.find(
		                    	(contact)=> {
                                    if (contact.id === userId) {
                                        return contact.email;
                                    }
                                }
		                    )
                            console.log("email: %o", contact.email);
                            let resp = userService.remove({email: contact.email});
                            resp.then(
                                (value) => {
		                            confirmAlert({title: "",message: "Remove User Successfully!", buttons: [{label: 'OK'}]});
                                    let resp = userService.getAll(page * pageSize, pageSize);
                                    resp.then(
                                        (resp) => {
                                            setUsers(resp.data);
                                        },
                                        (reason) => {
                                            console.log(reason)
		                                    confirmAlert({title: "",message: reason, buttons: [{label: 'OK'}]});
                                        }
                                    );
                                },
                                (reason) => {
                                    console.log(reason)
		                            confirmAlert({title: "", message: reason, buttons: [{label: 'OK'}]});
                                }
                            );

		                    //const newUsers = [...users];
		                    //newUsers.splice(index, 1);
		                    //setUsers(newUsers);
                        }
					},
					{
						label: 'No',
						onClick: () => {
							setEditUserId(null);
						}
					},
				]
			}
		);


	}
	const handleShowPassword = () => {
		if(showPassword=="text"){
			setShowPassWord("password")
		}else{
			setShowPassWord("text")
		}
	}
  	return (
  		<div className="app-container">
			<Button variant="info" onClick={handleUserFormShow}>New User</Button>

			<Modal show={show} onHide={handleUserFormClose}>
				<Modal.Header closeButton>
					<Modal.Title>Add a New User</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<form onSubmit={handleAddFormSubmit}>
						<Form.Control 
							type="email"
							name="email"
							required="required"
							placeholder="Enter a email..."
							onChange={handleAddFormChange}
						/>
						<Form.Control 
							type={showPassword}
							name="password"
							required="required"	
							placeholder="Enter a password..."
							onChange={handleAddFormChange}
						/>
						
						<Form.Control 
							type="text"
							name="full_name"
							required="required"
							placeholder="user full name..."
							onChange={handleAddFormChange}
						/>
						<div style={{marginTop:10}}><input type="checkbox" onClick={handleShowPassword}/>Show Password</div>
						<label>Role
							<select
								type="text"
								name="role_name"
								required="required"
								placeholder="None"
								// selectedValue={defaultRole}
								value={addFormData.role_name}
								onChange={handleAddFormChange}
							>
								{roles.map((role) => (
									<option value={role.name}>{role.name}</option>
								))}
							</select>
						</label>
						<label>Admin
							<select
								type="bool"
								name="is_superuser"
								required="required"
								placeholder="false"
								//selectedValue="false"
								value={addFormData.is_superuser}
								onChange={handleAddFormChange}>
								<option value="true">True</option>
								<option value="false">False</option>
							</select>
						</label>
						<label>Active
							<select
								type="bool"
								name="is_active"
								required="required"
								placeholder="true"
								//selectedValue="false"
								value={addFormData.is_active}
								onChange={handleAddFormChange}>
								<option value="true">True</option>
								<option value="false">False</option>
							</select>
						</label>
						{/* <Button type="submit" onClick={handleUserFormClose}>Add</Button> */}
						<Button type="submit">Add</Button>
					</form>
					
				</Modal.Body>
				<Modal.Footer>
				</Modal.Footer>
			</Modal>
        	
			<form onSubmit={handleEditFormSubmit}>
  	      	<Table striped bordered hover>
  	          	<thead>
					<tr>
  	              	    <th>ID</th>
  	              	    <th>Email</th>
  	              	    <th>Name</th>
  	              	    <th>Role</th>
  	              	    <th>Is Admin</th>
						<th>Is Active</th>
						<th>Actions</th>
  	              	</tr>
  	          	</thead>
  	            <tbody>
				  	{users.map((user) => (
						<Fragment>
							{ 
								editUserId === user.id ? 
								(<EditableRow
                                    roles={roles}
									editFormData={editFormData}
									handleEditFormChange={handleEditFormChange}
									handleCancelClick={handleCancelClick}
								/>) : 
								(<ReadOnlyRow
									user={user}
									handleEditClick={handleEditClick}
									handleDeleteClick={handleDeleteClick}
								/>)
							}
						</Fragment>
				  	))}
  	            </tbody>
                <div>
                    <Button onClick={handlePreviousPage}>Prev</Button>
                    <Button onClick={handleNextPage}>Next</Button>
                </div>
  	    	</Table>
			</form>
  	  	</div>
  	);
}

export default Users;
