import config from "config";
import * as actionTypes from "_actions";


const initialState = {
    ...config,
    isOpen: [], //for active default menu
    isTrigger: [], //for active default menu, set blank for horizontal
    needHelp:false,
    pdfConfig:{
        outline:false,
        fullScreenPDFViewerShow: false,
        pdfUrl: "",
        scrollable:false,
        name:"",
    }

}

export function navigation(state = initialState, action) {

    let trigger = [];
    let open = [];

    switch (action.type) {
        case actionTypes.ENABLE_FULL_SCREEN_NAVIGATION_REDUCER:
            return{
                ...state,
                pdfConfig:{
                    fullScreenPDFViewerShow: true,
                    pdfUrl:action.url,
                    outline:action.outline,
                    name:action.name
                }
            }
        case actionTypes.DISABLE_FULL_SCREEN_NAVIGATION_REDUCER:
            return {
                ...state,
                pdfConfig:{fullScreenPDFViewerShow: false, pdfUrl:"", outline:""},
            }
        case actionTypes.COLLAPSE_MENU:
            return {
                ...state,
                collapseMenu: !state.collapseMenu
            };
        case actionTypes.COLLAPSE_TOGGLE:
            if (action.menu.type === 'sub') {

                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }

                if (triggerIndex === -1) {
                    open = [...open, action.menu.id];
                    trigger = [...trigger, action.menu.id];
                }
            } else {
                open = state.isOpen;
                const triggerIndex = (state.isTrigger).indexOf(action.menu.id);
                trigger = (triggerIndex === -1) ? [action.menu.id] : [];
                open = (triggerIndex === -1) ? [action.menu.id] : [];
            }

            return {
                ...state,
                isOpen: open,
                isTrigger: trigger
            };
        case actionTypes.NAV_CONTENT_LEAVE:
            return {
                ...state,
                isOpen: open,
                isTrigger: trigger,
            };
        case actionTypes.NAV_COLLAPSE_LEAVE:
            if (action.menu.type === 'sub') {
                open = state.isOpen;
                trigger = state.isTrigger;

                const triggerIndex = trigger.indexOf(action.menu.id);
                if (triggerIndex > -1) {
                    open = open.filter(item => item !== action.menu.id);
                    trigger = trigger.filter(item => item !== action.menu.id);
                }
                return {
                    ...state,
                    isOpen: open,
                    isTrigger: trigger,
                };
            }
            return {...state};
        case actionTypes.FULL_SCREEN :
            return {
                ...state,
                isFullScreen: !state.isFullScreen
            };
        case actionTypes.FULL_SCREEN_EXIT:
            return {
                ...state,
                isFullScreen: false
            };
        case actionTypes.TOGGLE_DARK_MODE :
            return {
                ...state,
                isDarkMode: !state.isDarkMode
            };
        case actionTypes.ENABLE_DARK_MODE :
            return {
                ...state,
                isDarkMode: true
            };
        case actionTypes.DISABLE_DARK_MODE :
            return {
                ...state,
                isDarkMode: false
            };
        case actionTypes.NEED_HELP :
            return {
                ...state,
                needHelp: !state.needHelp
            };
        case actionTypes.CHANGE_LAYOUT:
            return {
                ...state,
                layout: action.layout
            };
        default:
            return state
    }
}
