import { userApi } from "../api";
import {getLocalToken, handleResponse, removeUser, removeLocalToken} from "./utils.service";

export const getAllExpForVisualizer = () => {
    return userApi.getExperimentsForVisualizer(getLocalToken());
}

export const getAllExpForWrangler = () => {
    const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    if (parseJwt.exp * 1000 < Date.now()) {
        removeUser();
        removeLocalToken();
        window.location.href = "/login"
      }
    return userApi.getExperimentsForWrangler(getLocalToken());
}

export const getSummaryStatistics = () => {
    return userApi.getSummaryStatistics(getLocalToken());
}

export const getExperimentViewer = (exp_id) => {
    return userApi.getExperimentViewer(exp_id, getLocalToken());
}

export const getExperimentList = () => {
    return userApi.getExperimentList(getLocalToken());
}

export const getExperimentCount = () => {
    return userApi.getExperimentCount(getLocalToken());
}

export const getAllExpForSteel = () => {
    return userApi.getAllExpForSteel(getLocalToken());
}

export const getAllExpForAl = () => {
    return userApi.getAllExpForAl(getLocalToken());
}

export const getAllExpriments = () => {
    return userApi.getAllExpriments(getLocalToken());
}

export const makeNewExperiment = (exp_in) => {
    return userApi.createExperiment(exp_in, getLocalToken());
}

export const makeNewExperimentFrame = (expFrame_in) => {
    return userApi.createExperimentFrame(expFrame_in, getLocalToken());
}

export const getAllProcessCount = () => {
    return userApi.getAllProcessCount(getLocalToken());
}

export const getAllUniExperiments = () => {
    return userApi.getAllUniExperiments(getLocalToken()).then(handleResponse).catch(handleResponse);
}

export const getExperimentViewerN = (exp_num) => {
    return userApi.getExperimentViewerN(exp_num, getLocalToken());
}

export const getImage = (image_link) => {
    return userApi.getImage(image_link, getLocalToken());
}

export const getExperimentCol = () => {
    return userApi.getExperimentCol(getLocalToken());
}

export const simpleSearch = (query, page, limit) => {
    return userApi.simpleSearch(query, page, limit, getLocalToken());
}

export const advancedSearch = (query) => {
    console.log('query:'+JSON.stringify(query))
    return userApi.advancedSearch(query, getLocalToken());
}

export const getDpResultFromServerImage = (image_link) => {
    return userApi.getDpResultFromServerImage(image_link, getLocalToken())
}

export const updateExperimentFrame = (frame_id, data) => {
    return userApi.updateExperimentFrame(frame_id, data, getLocalToken())
}
const experimentService = {
    getAllExpForVisualizer,
    getAllExpForWrangler,
    getSummaryStatistics,
    getExperimentViewer,
    getExperimentList,
    getImage,
    getExperimentCount,
    getExperimentViewerN,
    getExperimentCol,
    simpleSearch,
    advancedSearch,
    getDpResultFromServerImage
}