import config from "config";


export const getLocalToken = () => localStorage.getItem(config.project + "-token");

export const saveLocalToken = (token) => localStorage.setItem(config.project + "-token", token);

export const removeLocalToken = () => localStorage.removeItem(config.project + "-token");

export const getUser = () => JSON.parse(localStorage.getItem(config.project + "-user"));

export const saveUser = (user) => localStorage.setItem(config.project + "-user", JSON.stringify(user));

export const removeUser = () => localStorage.removeItem(config.project + "-user");

export function expiredToken() {
    const parseJwt = JSON.parse(atob(getLocalToken().split(".")[1]))

    if (parseJwt.exp * 1000 < Date.now()) {
        removeUser();
        removeLocalToken();
        window.location.href = "/login"
      }
}

export function authHeaders(token) {
    return {
        headers: {
            Authorization: `Bearer ${token}`,
            accept: 'application/json'
        },
    };
}

export function handleResponse(response) {
    let resp = response.response;

    if (resp) {
        if (resp.status === 400) {
            return Promise.reject(resp.data.detail);
        }
    } else if (response.status === 200) {
        return response
    }
    else if(response.status ===401){
        removeUser();
        removeLocalToken();
        window.location.href = "/login"
    }
    else if(response.status ===403){
        removeUser();
        removeLocalToken();
        window.location.href = "/login"
    }
    else if(response.status === 500){
        removeUser();
        removeLocalToken();
        window.location.href = "/404"
    }
     else {
        return Promise.reject(response);
    }
}
