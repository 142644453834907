import { combineReducers } from 'redux';

import { authentication } from '_reducers/authentication.reducer';
import { registration } from '_reducers/registration.reducer';
import { users } from '_reducers/users.reducer';
import { alert } from '_reducers/alert.reducer';
import { navigation } from '_reducers/navigation.reducer';
import {
  files
} from '_reducers/file.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  alert,
  navigation,
  files
});

export default rootReducer;
