import React from 'react';
import config from "config";

class WebsocketExample extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            count: 90,
            analysis:false,
            message:'',
        };
        this.runAnalysis.bind(this);
    }

    handleData(data) {
        // let result = JSON.parse(data);
        console.log(data)
        // this.setState({message:result.movement});
    }

    runAnalysis(){
        this.setState({analysis:true})
    }

    render() {
        return (
            <div>
                {/*<button onClick={this.handleData.bind(this)}>Run Analysis</button>*/}

                {/*<p>{this.state.message}</p>*/}

                {/*<Websocket url={config.webSocketUrl+'/api/v1/socket/ws'}*/}
                {/*           onMessage={this.handleData.bind(this)}/>*/}
                <iframe src="https://robtaussig.com/socket/" style={{width:"800px", height:"1600px"}}/>
            </div>
        );
    }
}

export default WebsocketExample;


