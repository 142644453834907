import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {getUser} from "_services/utils.service";

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
        // Disable User Authetication to work on front-end only. Might break in the future! Use with Caution
        // <Component {...props} />
        getUser()
            ? <Component {...props} />
            : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
    )} />
)