export default {
    items: [{
        id: 'navigation',
        title: 'Navigation',
        type: 'group',
        icon: 'icon-navigation',
        children: [{
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            url: '/dashboard',
            icon: 'fas fa-home',
        }]
    },
    {
        id: 'tools',
        title: 'Tools',
        type: 'group',
        icon: 'icon-navigation',
        children: [{
            id: 'file_manager',
            title: 'File Manager',
            type: 'item',
            url: '/dashboard/filemanager',
            icon: 'fas fa-file',
        },
        {
            id: 'chart_template',
            title: 'Chart Template',
            type: 'item',
            url: '/dashboard/template',
            icon: 'fas fa-lightbulb',
        },
        {
            id: 'deep_learning',
            title: 'Corrosion Assessor',
            type: 'item',
            url: '/dashboard/deeplearning',
            icon: 'fas fa-microchip',
        },
        {//test page
            id: 'deeplearning2',
            title: 'PLACEHOLDER_DL_MODEL_2',
            type: 'item',
            url: '/dashboard/deeplearning2',
            icon: 'fas fa-microchip',
        },
        ]
    },
    {
        id: 'features',
        title: 'Features',
        type: 'group',
        icon: 'icon-navigation',
        children: [
            {
                id: 'experimentView',
                title: 'Experiment View',
                type: 'item',
                url: '/dashboard/experimentView',
                icon: 'fas fa-list',
            },
            {
                id: 'experimentAnalysis',
                title: 'Experiment Analysis',
                type: 'item',
                url: '/dashboard/experimentAnalysis',
                icon: 'fas fa-chart-line',
            },
            {
                id: 'experimentSearch',
                title: 'Experiment Search',
                type: 'item',
                url: '/dashboard/experimentSearch',
                icon: 'fas fa-search',
            },
            {
                id: 'sessionView',
                title: 'Session View',
                type: 'item',
                url: '/dashboard/sessionView',
                icon: 'fas fa-list',
            }
        ]
    },
    ]
}
