export default function assignPrototypes(){

  Array.prototype.max = function () {
      return Math.max.apply(null, this);
  };

  Array.prototype.min = function () {
      return Math.min.apply(null, this);
  };
}
